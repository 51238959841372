<template>
    <div class="aboutInfo-wrap">
        <vue-scroll-snap >
            <div class="item about-wrap">
                <AboutIntro :aboutInfo="aboutInfo" />        
            </div>
            <div class="item specialize-wrap">
                <Specialize :specializeInfo="specializeInfo"/>       
            </div>
            <div class="item experience-wrap">
                <Experience :experienceInfo="experienceInfo"/>       
            </div>
            <div class="item project-wrap">
                <Projects :projectsInfo="projectsInfo"/>
            </div>
          <div class="item testimonial-wrap">
                <AboutTestimonails :aboutTestimonail="aboutTestimonail" />
          </div>
          <div class="item directContact-wrap">
                <DirectContact :directContact="directContact"/>
          </div>
        </vue-scroll-snap>
    </div>
</template>
<script>
import VueScrollSnap from "vue-scroll-snap"
import AboutIntro from "./partials/AboutIntro.vue"
import Specialize from "./partials/Specialize.vue"
import Experience from "./partials/Experience.vue"
import Projects from "./partials/Projects.vue"
import AboutTestimonails from "./partials/AboutTestimonials.vue"
import DirectContact from "./partials/DirectContact.vue"

import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"

export default{
    name: 'AboutMe',
    components :{
        VueScrollSnap,
        AboutIntro,
        Specialize,
        Experience,
        Projects,
        AboutTestimonails,
        DirectContact
    },
    data() {
        return {
            aboutInfo:{
                slogan:"",
                name:"",
                facebook:"",
                youtube:"",
                instagram:"",
            },
            specializeInfo:{
                id:"",
                detail:"",
                image:"",
                experienceYear:"",
            },
            experienceInfo:[],
            projectsInfo:[],
            aboutTestimonail:[],
            directContact:{
                address:"",
                email:"",
                phone:"",
                phone_display:"",
                description:"",
            },
        }
    },
    mounted() {
        document.title = 'About Us';
        let app = this;
        app.renderData();
    },
    methods:{
        renderData(){
            let app = this;
            Helper.showSpinner();
            axios.get(api.getUrl('/profile/1')).then(function (response) {
                if(response.data.success){
                    app.aboutInfo.slogan    = response.data.data.slogan;
                    app.aboutInfo.name      = response.data.data.name;
                    app.aboutInfo.facebook  = response.data.data.facebook;
                    app.aboutInfo.youtube   = response.data.data.youtube;
                    app.aboutInfo.instagram = response.data.data.instagram;

                    app.specializeInfo.id = response.data.data.id;
                    app.specializeInfo.detail = response.data.data.skill_detail;
                    app.specializeInfo.image = response.data.data.image;
                    app.specializeInfo.experienceYear = response.data.data.expyear;

                    app.experienceInfo      = response.data.data.experience;
                    app.projectsInfo        = response.data.data.projects;
                    app.aboutTestimonail    = response.data.data.testimonials;

                    app.directContact.address = response.data.data.address;
                    app.directContact.phone = response.data.data.phone;
                    app.directContact.phone_display = response.data.data.phone_display;
                    app.directContact.email = response.data.data.email;
                    app.directContact.description = response.data.data.description;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    },
}
</script>
<style src="../../assets/css/about.css"></style>