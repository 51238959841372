<template>
    <div class="container">
        <div class="row justify-content-evenly">
            <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                <span class="icon-quote"></span>
                <h2>Hey, this is <br> <span class="brand-color">testimonials</span> from my best clients & <br> partners</h2>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
                <template v-if="data.length > 0">
                <carousel :autoplay="true" :nav="false" :items="1" :margin="20" :dots="true">
                    <div class="testimonail-innerSlider" v-for="(item,i) in data" :key="i">
                        <h3>{{item.title}}</h3>
                        <span>{{item.company}}</span>
                        <p class="mt-5"> {{item.description}} </p>
                    </div>
                </carousel>
            </template>
            </div>
        </div>
    </div>
</template>
<script>
import carousel from 'vue-owl-carousel'
export default{
    name:'AboutTestimonails',
    components: {
        carousel
    },
    props:{
        aboutTestimonail:{
            required:true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            data: this.aboutTestimonail,
        }
    },
    watch: {
        aboutTestimonail(value) {
            let app = this;
            app.data = value;
        },
    },
}
</script>