<template>
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-xl-4 col-lg-5 col-md-6 mb-4">
                <div class="contact-info">
                    <h2>Contact</h2>
                    <div v-html="data.description"></div>
                    <span class="address d-block">{{data.address}}</span>
                    <a :href="'tel:'+ data.phone" class="d-block phone brand-color"> {{data.phone_display}} </a>
                    <a :href="'mailto:'+data.email" class="d-block email"> {{data.email}} </a>
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-6">
                <div class="contact-form">
                    <h3>Let's grab a coffee and jump on conversation <span class="brand-color">chat with me.</span></h3>
                    <ContactForm/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContactForm from '@/components/ContactFrom';
export default{
    name: 'DirectContact',
    components:{ContactForm},
    props:{
        directContact:{
            required:true,
            type:[Object],
        }
    },
    data() {
        return {
            data: this.directContact,
        }
    },
    watch: {
        directContact(value) {
            let app = this;
            app.data = value;
        },
    },
}
</script>