<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>Experience</h2>
                <template v-if="data.length > 0">
                <ul>
                    <li v-for="(item,i) in data" :key="i">
                        <div class="exp-tool">
                            <img :src="item.icon" class="img-fluid">
                        </div>
                        <div class="exp-tool-name">
                            <h3>{{item.title}}</h3>
                        </div>
                        <div class="exp-tool-dscp" v-html="item.description"></div>
                    </li>
                </ul>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'Experience',
    props:{
        experienceInfo:{
            required:true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            data: this.experienceInfo,
        }
    },
    watch: {
        experienceInfo(value) {
            let app = this;
            app.data = value;
        },
    },
}
</script>