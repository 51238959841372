<template>
    <div>
        <div class="form-group mb-4 row">
            <div class="col-xl-6 mt-3">
                <label>Name <span class="required-field">*</span></label>
                <input type="text" @keydown.enter="focusNext" ref="input-0" placeholder="Name" class="univ-input" v-model="formReq.name" />
                <span class="error-field" v-if="formErrors.name"><span class="icon-warning"></span> {{ formErrors.name }}</span>
            </div>
            <div class="col-xl-6 mt-3">
                <label>Phone <span class="required-field">*</span></label>
                <input type="text" @keydown.enter="focusNext" ref="input-1" placeholder="Phone number" class="univ-input" v-model="formReq.phone_number" />
                <span class="error-field" v-if="formErrors.phone_number"><span class="icon-warning"></span> {{ formErrors.phone_number }}</span>
            </div>
        </div>
        <div class="form-group mb-4">
            <label>Email <span class="required-field">*</span></label>
            <input type="email" @keydown.enter="focusNext" ref="input-2" placeholder="Email"  class="univ-input" v-model="formReq.email" />
            <span class="error-field" v-if="formErrors.email"><span class="icon-warning"></span> {{ formErrors.email }}</span>
        </div>
        <div class="form-group mb-4">
            <label>Message</label>
            <textarea v-model="formReq.message" @keydown.enter="focusNext" ref="input-3" placeholder="Message" class="univ-input" rows="4"></textarea>
        </div>

        <div class="form-group">
            <button class="univ-btn" @click="formSubmit()" @keydown.enter="focusNext" ref="input-4" > <b-spinner v-if="loader" small></b-spinner> Send </button>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";
export default {
    name: "ContactForm",
    data(){
        return {
            formReq:{
                name:'',
                email: '',
                phone_number:'',
                message: '',
            },
            formErrors: {
                name:"",
                email: "",
                phone_number:"",
            },
            loader:false,
            currentIndex: 0,
        }
    },
    methods: {
        formSubmit(){
            let app = this;
            app.currentIndex = 0;
            app.clearedError();
            app.loader = true;
            axios.post(api.getUrl('/send-contact'),app.formReq).then((response) => {
                if(response.data.success == true){
                    app.loader = false;
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 2000});
                    app.clearedForm();
                }else{
                    if(response.data.message){
                        app.loader = false;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 2000});
                    }else if(response.data.errors.length > 0){

                        app.loader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.formErrors.name = item.message;
                            }else if(item.key == "email"){
                                app.formErrors.email = item.message;
                            }else if(item.key == "phone_number"){
                                app.formErrors.phone_number = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.formErrors.name         = "";
            app.formErrors.email        = "";
            app.formErrors.phone_number= "";
        },
        clearedForm(){
            let app = this;
            app.formReq.name         = "";
            app.formReq.email        = "";
            app.formReq.phone_number = "";
            app.formReq.message      = "";
        },
        focusNext() {
            let app = this;
            app.currentIndex += 1
            const nextElement = app.$refs[`input-${app.currentIndex}`]
            if (nextElement) nextElement.focus()
        },
    },
    
}
</script>
